// Overrides
$grid-gutter-width: 40px !default;
$border-radius-base:  4px !default;
$padding-base-vertical: 14px !default;
$hr-border: rgba(255, 255, 255, 0.3);

@import '../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
@import 'animate';
@import 'icomoon';
@import 'simple-line-icons';
@import 'owl.theme.default';

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/icomoon.eot?srf3rx");
  src: url("../fonts/icomoon/icomoon.eot?srf3rx#iefix") format("embedded-opentype"), url("../fonts/icomoon/icomoon.ttf?srf3rx") format("truetype"), url("../fonts/icomoon/icomoon.woff?srf3rx") format("woff"), url("../fonts/icomoon/icomoon.svg?srf3rx#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #6a6a6a;
  background: #fff;
  height: 100%;
  position: relative;
}
body.fh5co-overflow-x {
  overflow-x: hidden;
}

a {
  color: #E4365B;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
a:hover, a:active, a:focus {
  color: #4fd2c2;
  outline: none;
  text-decoration: none;
}

p {
  margin-bottom: 1.5em;
}

h1, h2, h3, h4, h5, h6 {
  color: #000;
  font-family: "Montserrat", arial, sans-serif;
  font-weight: 700;
}

::-webkit-selection {
  color: #818892;
  background: #f9f6f0;
}

::-moz-selection {
  color: #818892;
  background: #f9f6f0;
}

::selection {
  color: #818892;
  background: #f9f6f0;
}

@media all and (max-width: 527px) {
  .hide-xs {
    display: none !important;
  }
  .btn {
    font-size: 11px;
  }
}

#fh5co-menu {
  background: transparent;
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  padding-bottom: 20px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  margin-top: 20px;
}
#fh5co-menu.sleep {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
#fh5co-menu.fh5co-sleep {
  -webkit-transform: translateX(-290px) !important;
  -moz-transform: translateX(-290px) !important;
  -ms-transform: translateX(-290px) !important;
  -o-transform: translateX(-290px) !important;
  transform: translateX(-290px) !important;
}
.scrolled #fh5co-menu {
  background: #fff;
  position: fixed;
  margin-top: 0px;
  top: 0;
  border-bottom: 1px solid #ccc;
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
}

.scrolled #fh5co-menu .sm {
  display: block !important;
  padding: 10px 20px;
}
.scrolled #fh5co-menu .xl {
  display: none !important;
}

#fh5co-page {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  float: left;
  background: #fff;
  width: 100%;
  position: relative;
  z-index: 89;
}
#fh5co-page.fh5co-sleep {
  border-right: 1px solid #ccc;
  -webkit-transform: translateX(-290px);
  -moz-transform: translateX(-290px);
  -ms-transform: translateX(-290px);
  -o-transform: translateX(-290px);
  transform: translateX(-290px);
}

#fh5co-wrap {
  z-index: 89;
  position: relative;
  float: left;
  width: 100%;
  background: #fff;
}

#fh5co-offcanvass {
  position: fixed;
  z-index: 90;
  top: 0;
  right: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateX(290px);
  -moz-transform: translateX(290px);
  -ms-transform: translateX(290px);
  -o-transform: translateX(290px);
  transform: translateX(290px);
  bottom: 0;
  width: 290px;
  padding: 40px 30px;
  background: #fff;
  color: #878c93;
}
#fh5co-offcanvass .fh5co-lead {
  font-size: 12px;
  letter-spacing: 2px;
  color: #ccc;
  text-transform: uppercase;
  margin-bottom: 20px;
}
#fh5co-offcanvass ul {
  padding: 0;
  margin: 30px 0 30px 0;
}
#fh5co-offcanvass ul li {
  padding: 0;
  margin: 0;
  list-style: none;
}
#fh5co-offcanvass ul li a {
  display: block;
  padding: 10px 4px;
  border-bottom: 1px solid #eaeaea;
  font-size: 18px;
  color: #444;
}
#fh5co-offcanvass ul li a:hover, #fh5co-offcanvass ul li a:focus, #fh5co-offcanvass ul li a:active {
  outline: none;
  text-decoration: none;
  color: #E4365B;
}
#fh5co-offcanvass ul li.active a {
  color: #E4365B !important;
}
#fh5co-offcanvass.fh5co-awake {
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
}
#fh5co-offcanvass .fh5co-offcanvass-close {
  position: absolute;
  right: 20px;
  top: 40px;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 2px;
  font-family: "Montserrat", arial, sans-serif;
  z-index: 99;
  float: left;
  color: #444;
}
#fh5co-offcanvass .fh5co-offcanvass-close i {
  font-size: 20px;
  margin-top: 3px;
  float: right;
  margin-left: 10px;
  color: red;
}
#fh5co-offcanvass .fh5co-offcanvass-close:hover, #fh5co-offcanvass .fh5co-offcanvass-close:focus, #fh5co-offcanvass .fh5co-offcanvass-close:active {
  outline: none;
  text-decoration: none;
}
#fh5co-offcanvass .fh5co-logo {
  display: block;
  float: left;
  width: 100%;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 0;
  margin: 40px 0 0 0;
}
#fh5co-offcanvass .fh5co-logo a {
  color: #000;
  padding: 0;
  margin: 0;
}

.fh5co-menu-btn {
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  float: right;
  margin-top: 16px;
  position: relative;
  z-index: 10;
  font-family: "Montserrat", arial, sans-serif;
  color: #fff;
}
.fh5co-menu-btn i {
  font-size: 20px;
  margin-top: 3px;
  float: right;
  margin-left: 10px;
}
.fh5co-menu-btn:hover, .fh5co-menu-btn:focus, .fh5co-menu-btn:active {
  outline: none;
  text-decoration: none;
  color: #fff;
}

#fh5co-hero {
  float: left;
  width: 100%;
  position: relative;
  padding-top: 4em;
  padding-bottom: 9em;
  height: auto;
  z-index: 10;
  background: #4fd2c2;
  overflow: hidden;
}
#fh5co-hero.home {
  height: 600px;
  background: url(/assets/images/design/bg-home.jpg) top left;
  background-size: cover;
}
#fh5co-hero.florists {
  background: url(/assets/images/design/bg-florists.jpg) top left;
  background-size: cover;
}
#fh5co-hero.city {
  background: url(/assets/images/design/bg-city.jpg) center left;
  background-size: cover;
}
#fh5co-hero .fh5co-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background: #3b3d40;
  opacity: .5;
}
@media screen and (max-width: 768px) {
  #fh5co-hero {
    padding-top: 0;
    padding-bottom: 14em;
  }
}
#fh5co-hero .fh5co-intro {
  width: 100%;
  color: #fff;
  z-index: 12;
  position: relative;
}
#fh5co-hero .fh5co-intro .fh5co-intro-img {
  text-align: right;
  bottom: -5em;
}
#fh5co-hero .fh5co-intro h1,
#fh5co-hero .fh5co-intro h2 {
  color: #fff;
  font-size: 50px;
  line-height: 50px;
  font-weight: 700;
}
@media screen and (max-width: 768px) {
  #fh5co-hero .fh5co-intro .fh5co-intro-img {
    bottom: -10em;
  }
  #fh5co-hero .fh5co-intro .fh5co-intro-img img {
    max-width: 100%;
  }
}
#fh5co-hero .fh5co-intro .fh5co-text {
  bottom: -6em;
}
#fh5co-hero.home .fh5co-intro .fh5co-text {
  bottom: -12em;
}
@media screen and (max-width: 1198px) {
  #fh5co-hero .fh5co-intro .fh5co-text {
    bottom: 0;
    bottom: -8em;
  }
}
@media screen and (max-width: 768px) {
  #fh5co-hero .fh5co-intro .fh5co-text {
    bottom: 0;
    bottom: -12em;
  }
}
#fh5co-hero .fh5co-intro h2 {
  color: #fff;
  font-size: 50px;
  line-height: 50px;
  font-weight: 700;
}
@media screen and (max-width: 768px) {
  #fh5co-hero .fh5co-intro h2 {
    font-size: 40px;
    line-height: 40px;
  }
}
#fh5co-hero .fh5co-intro p {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: .05em;
}
#fh5co-hero .fh5co-intro .btn {
  background: #E4365B;
}
#fh5co-hero .fh5co-intro .btn i {
  font-size: 20px;
  vertical-align: middle;
  margin-top: -5px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
}

.navbar-brand {
  text-align: left;
  font-weight: bold;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 20px;
  float: left !important;
  font-family: "Montserrat", arial, sans-serif;
  color: #fff;
  z-index: 10;
  position: relative;
}
.navbar-brand.sm {
  display: none;
}

.navbar-brand > span {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  float: left;
  border: 2px solid #fff;
  padding: 7px 10px 7px 12px;
}
.navbar-brand:hover {
  color: #fff;
  opacity: .8;
}
@media screen and (max-width: 480px) {
  .navbar-brand {
    margin: inherit;
    text-align: left;
    padding-left: 0;
    margin-left: 0;
  }
}

.scrolled .navbar-brand {
  color: #444;
}
.scrolled .navbar-brand:hover {
  color: #000;
}
.scrolled .navbar-brand > span {
  border-color: #444;
}

#fh5co-clients {
  padding: 2em 0;
  background: #E4365B;
  position: relative;
}
#fh5co-clients .fh5co-client {
  margin-bottom: 0px;
}
@media screen and (max-width: 992px) {
  #fh5co-clients .fh5co-client {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  #fh5co-clients .fh5co-client {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 480px) {
  #fh5co-clients .fh5co-client img {
    max-width: 100%;
  }
}

#fh5co-pricing {
  padding: 7em 0;
  position: relative;
  border-bottom: 1px solid #f2f2f2;
}
#fh5co-pricing .fh5co-price {
  padding-top: 1em;
  display: block;
  font-size: 50px;
  line-height: 50px;
  font-family: "Montserrat", arial, sans-serif;
  color: #4fd2c2;
}
#fh5co-pricing .fh5co-price > span {
  font-size: 16px;
  line-height: 16px;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  color: #000;
}
#fh5co-pricing .pricing-feature {
  border-top: 10px solid #4fd2c2;
}

#fh5co-subscribe {
  padding: 6em 0 5em;
  position: relative;
  background: #4fd2c2;
}
#fh5co-subscribe h2 {
  color: #fff;
  font-size: 26px;
}
#fh5co-subscribe .form-control {
  color: #fff;
  border: 2px solid #fff;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
#fh5co-subscribe .form-control:hover {
  color: #fff;
  background: rgba(255, 255, 255, .15);
}
#fh5co-subscribe .form-control:focus,
#fh5co-subscribe .form-control:active {
  color: #333;
  background: rgba(255, 255, 255, .75);
}
#fh5co-subscribe .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
#fh5co-subscribe .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.5);
}
#fh5co-subscribe .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.5);
}
#fh5co-subscribe .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
#fh5co-subscribe .form-control:focus::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
#fh5co-subscribe .form-control:focus:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.3);
}
#fh5co-subscribe .form-control:focus::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.3);
}
#fh5co-subscribe .form-control:focus:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
#fh5co-subscribe .btn {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: #30beac;
}
#fh5co-subscribe .btn i {
  font-size: 20px;
  vertical-align: middle;
  margin-top: -5px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
}
#fh5co-subscribe .form-group {
  margin-bottom: 0px;
}

#mce-success-response {
  background: rgba(255, 255, 255, .25);
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
}

#mce-error-response {
  background: #E4365B;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
}
#mce-success-response a,
#mce-error-response a {
  color: rgba(255, 255, 255, .75);
}

@media screen and (max-width: 768px) {
  #fh5co-subscribe .form-group {
    margin-bottom: 20px;
  }
}

#fh5co-products,
#fh5co-features {
  border-bottom: 1px solid #f2f2f2;
}

#fh5co-features,
#fh5co-features-2,
#fh5co-features-3,
#fh5co-products,
#fh5co-mentions {
  position: relative;
  padding: 6em 0;
}
@media screen and (max-width: 768px) {
  #fh5co-features,
  #fh5co-features-2,
  #fh5co-features-3,
  #fh5co-products,
  #fh5co-mentions {
    padding: 4em 0;
  }
}

#fh5co-features,
#fh5co-feature-3,
#fh5co-mentions {
  background: #fafafa;
}

#fh5co-features-2 {
  background: #fff;
}

#fh5co-features-3 .fh5co-text-wrap {
  margin-bottom: 2em;
}
@media screen and (max-width: 1200px) {
  #fh5co-features-3 .fh5co-text-wrap {
    margin-top: 0em;
  }
}
@media screen and (max-width: 992px) {
  #fh5co-features-3 .fh5co-text-wrap {
    margin-top: 0em;
  }
}
#fh5co-features-3 .fh5co-text-wrap .fh5co-text {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  #fh5co-features-3 .fh5co-text-wrap .fh5co-text {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#fh5co-features .fh5co-icon{
  font-size: 20px;
}

#fh5co-features-3 .fh5co-icon {
  font-size: 40px;
  display: block;
  margin-bottom: 20px;
}
#fh5co-features-3 .fh5co-icon i {
  color: #4fd2c2;
}

#fh5co-features-2 .fh5co-text-wrap {
  margin-top: 5em;
}
@media screen and (max-width: 1200px) {
  #fh5co-features-2 .fh5co-text-wrap {
    margin-top: 0em;
  }
}
@media screen and (max-width: 992px) {
  #fh5co-features-2 .fh5co-text-wrap {
    margin-top: 0em;
  }
}
#fh5co-features-2 .fh5co-text-wrap .fh5co-text {
  padding-left: 3em;
  padding-right: 3em;
}
@media screen and (max-width: 768px) {
  #fh5co-features-2 .fh5co-text-wrap .fh5co-text {
    padding-left: 15px;
    padding-right: 15px;
  }
}
#fh5co-features-2 .fh5co-icon {
  font-size: 40px;
  display: block;
  margin-bottom: 20px;
}
#fh5co-features-2 .fh5co-icon i {
  color: #4fd2c2;
}
@media screen and (max-width: 992px) {
  #fh5co-features-2 .fh5co-image {
    margin-top: 30px;
  }
}
#fh5co-features-2 .fh5co-image img {
  max-width: 100%;
}
@media screen and (max-width: 480px) {
  #fh5co-features-2 .fh5co-image img {
    max-width: 100%;
  }
}

#fh5co-mentions .mention-accordion {
  position: relative;
  float: left;
  width: 100%;
  padding: 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
@media screen and (max-width: 768px) {
  #fh5co-mentions .mention-accordion {
    padding: 15px;
  }
}
#fh5co-mentions .mention-accordion .mention-accordion-icon-toggle {
  position: absolute;
  top: 27px;
  right: 30px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
@media screen and (max-width: 480px) {
  #fh5co-mentions .mention-accordion .mention-accordion-icon-toggle {
    top: 17px;
  }
}
#fh5co-mentions .mention-accordion .mention-accordion-icon-toggle i {
  font-size: 20px;
  color: #4fd2c2;
}
#fh5co-mentions .mention-accordion h3 {
  font-size: 26px;
  line-height: 1.5;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: #999;
  cursor: pointer;
  position: relative;
  display: block;
}
@media screen and (max-width: 768px) {
  #fh5co-mentions .mention-accordion h3 {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
    width: 80%;
    float: left;
  }
}
#fh5co-mentions .mention-accordion .mention-body {
  clear: both;
  display: none;
  padding: 30px 0 0px 0;
  color: #444;
  font-size: 16px;
  line-height: 30px;
  overflow: hidden;
}
#fh5co-mentions .mention-accordion.active {
  background: #fff;
  -webkit-box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.05);
}
#fh5co-mentions .mention-accordion.active h3 {
  color: #E4365B;
}
#fh5co-mentions .mention-accordion.active .mention-accordion-icon-toggle {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.fh5co-feature, .fh5co-figure {
  display: block;
  color: #444;
  float: left;
  width: 100%;
  text-align: center;
  background: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  padding: 40px 10px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 1.5em;
}
.fh5co-feature:hover, .fh5co-feature:focus, .fh5co-feature:active, .fh5co-figure:hover, .fh5co-figure:focus, .fh5co-figure:active {
  text-decoration: none;
  outline: none;
}
.fh5co-feature .fh5co-feature-icon, .fh5co-figure .fh5co-feature-icon {
  width: 80px;
  height: 80px;
  display: block;
  display: table;
  text-align: center;
  margin: 0 auto;
  position: relative;
  border: 2px solid #4fd2c2;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.fh5co-feature .fh5co-feature-icon i, .fh5co-figure .fh5co-feature-icon i {
  display: table-cell;
  vertical-align: middle;
  font-size: 50px;
  color: #4fd2c2;
}
.fh5co-feature .fh5co-feature-lead, .fh5co-feature .fh5co-figure-lead, .fh5co-figure .fh5co-feature-lead, .fh5co-figure .fh5co-figure-lead {
  font-size: 12px;
  letter-spacing: 2px;
  font-family: "Montserrat", arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}
.fh5co-feature .fh5co-feature-text, .fh5co-feature .fh5co-figure-text, .fh5co-figure .fh5co-feature-text, .fh5co-figure .fh5co-figure-text {
  color: #919191;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 24px;
}
.fh5co-feature:hover, .fh5co-feature.active, .fh5co-figure:hover, .fh5co-figure.active {
  -webkit-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
}
@media screen and (max-width: 768px) {
  .fh5co-feature:hover, .fh5co-feature.active, .fh5co-figure:hover, .fh5co-figure.active {
    margin-top: 0px;
  }
}
.fh5co-feature:hover .fh5co-feature-icon, .fh5co-feature.active .fh5co-feature-icon, .fh5co-figure:hover .fh5co-feature-icon, .fh5co-figure.active .fh5co-feature-icon {
  background: #4fd2c2;
}
.fh5co-feature:hover .fh5co-feature-icon i, .fh5co-feature.active .fh5co-feature-icon i, .fh5co-figure:hover .fh5co-feature-icon i, .fh5co-figure.active .fh5co-feature-icon i {
  color: #fff;
}

.fh5co-figure {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.fh5co-figure figure {
  margin-bottom: 50px;
  float: left;
  width: 100%;
}
.fh5co-figure img {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 1;
}
.fh5co-figure:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

#fh5co-products {
  background: #fafafa;
}

.fh5co-uppercase-sm {
  font-size: 12px;
  letter-spacing: 2px;
  font-family: "Montserrat", arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.fh5co-section-heading {
  margin-bottom: 4em;
}
.fh5co-section-heading .fh5co-lead {
  font-size: 45px;
  line-height: 60px;
}
.fh5co-section-heading-tag {
  margin-bottom: 0;
}
.fh5co-section-tag .fh5co-tagline {
  color: #fff;
  font-size: 35px;
  line-height: 40px;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .fh5co-section-heading .fh5co-lead {
    font-size: 35px;
    line-height: 50px;
  }
}
.fh5co-section-heading .emp {
  font-family: "Playfair Display", times, serif;
  font-style: italic;
  font-weight: 400;
}
.fh5co-section-heading .fh5co-sub {
  font-size: 18px;
  line-height: 30px;
}

#fh5co-testimony {
  background: #4fd2c2;
  color: #fff;
  padding: 8em 0;
}
#fh5co-testimony .fh5co-vcard img {
  width: 170px;
  height: 170px;
  margin-bottom: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
@media screen and (max-width: 992px) {
  #fh5co-testimony .fh5co-vcard img {
    width: auto;
    height: auto;
  }
}
@media screen and (max-width: 768px) {
  #fh5co-testimony .fh5co-vcard img {
    width: 120px;
    height: 120px;
  }
}
@media screen and (max-width: 480px) {
  #fh5co-testimony .fh5co-vcard img {
    width: 100px;
    height: 100px;
    margin: 0 auto 20px auto;
  }
}
#fh5co-testimony blockquote {
  padding: 0;
  border-left: none;
  color: #fff;
}
@media screen and (max-width: 480px) {
  #fh5co-testimony blockquote {
    text-align: center;
  }
}
#fh5co-testimony blockquote p {
  font-size: 30px;
  line-height: 45px;
}
@media screen and (max-width: 768px) {
  #fh5co-testimony blockquote p {
    font-size: 20px;
    line-height: 35px;
  }
}
#fh5co-testimony .fh5co-author {
  font-family: "Montserrat", arial, sans-serif;
  color: #fff;
}
@media screen and (max-width: 480px) {
  #fh5co-testimony .fh5co-author {
    text-align: center;
  }
}
#fh5co-testimony .fh5co-author > span {
  color: #000;
}

.fh5co-bg-section {
  color: #fff;
  background-color: #e9e9e9;
  background-size: cover;
  background-position: top center;
  margin-top: 0px;
  z-index: 10;
  position: relative;
}
.fh5co-bg-section .fh5co-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #3b3d40;
  z-index: 11;
}

.fh5co-hero-wrap, .fh5co-bg-section {
  padding: 4em 0;
}
@media screen and (max-width: 768px) {
  .fh5co-hero-wrap, .fh5co-bg-section {
    height: inherit;
    padding: 3em 0;
  }
}

.fh5co-bg-section .fh5co-hero-wrap {
  display: table;
  width: 100%;
  z-index: 12;
  position: relative;
}
.fh5co-bg-section .fh5co-hero-wrap .fh5co-hero-intro {
  display: table-cell;
  vertical-align: middle;
}
.fh5co-bg-section .fh5co-hero-wrap .fh5co-hero-intro .fh5co-lead, .fh5co-bg-section .fh5co-hero-wrap .fh5co-hero-intro .fh5co-sub-lead {
  color: #fff;
  margin: 0;
  padding: 0;
  line-height: 1.5;
}
.fh5co-bg-section .fh5co-hero-wrap .fh5co-hero-intro .fh5co-lead {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .fh5co-bg-section, .fh5co-hero-wrap {
    height: inherit;
  }
}

.fh5co-bg-section .fh5co-overlay {
  background: #3b3d40;
  opacity: .5;
}
.fh5co-bg-section .author {
  font-size: 20px;
  margin-bottom: 0;
}

#fh5co-main,
#fh5co-footer {
  float: left;
  width: 100%;
}

.cta .btn {
  margin-bottom: 0;
}

pre {
  background: #e9e9e9;
  border: 1px solid #ccc;
}

/* Buttons */
.btn {
  font-family: "Montserrat", arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 20px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  border-radius: 40px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 10px;
  font-size: 11px;
  font-weight: 400;
}
.btn:hover, .btn:focus, .btn:active {
  outline: none !important;
  background: #30beac !important;
  border-color: transparent !important;
}
.btn:hover {
  border-color: transparent;
  -webkit-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.25);
  -ms-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.25);
  -o-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.25);
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.25);
}
.btn.fh5co-btn-icon {
  text-transform: none !important;
  letter-spacing: normal !important;
  padding-left: 15px;
  padding-right: 15px;
}

.btn-outline {
  border: 2px solid #ccc !important;
  background: transparent;
  color: #2a2e37;
}
.btn-outline:hover, .btn-outline:active, .btn-outline:focus {
  border: 2px solid #4fd2c2 !important;
  background: #4fd2c2 !important;
  color: #fff;
}

.btn-primary {
  background: #E4365B;
}

.btn-success {
  background: #25E4CC;
}

.btn-danger {
  background: #d9534f;
}

.btn-info {
  background: #5bc0de;
}

.btn-warning {
  background: #f0ad4e;
}

.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  border-color: transparent;
}

.form-control {
  box-shadow: none !important;
  border: 2px solid #444;
  background: transparent;
  font-family: "Montserrat", arial, sans-serif;
}
.form-control:hover, .form-control:focus, .form-control:active {
  outline: none;
  box-shadow: none !important;
  border: 2px solid #4fd2c2;
}

.btn-sm, .btn-group-sm > .btn {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn-xs, .btn-group-xs > .btn {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.fh5co-social {
  padding: 0;
  margin: 0;
}
.fh5co-social li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}
.fh5co-social li a {
  display: block;
  float: left;
  margin: 0;
  padding: 0;
}
.fh5co-social li a:hover, .fh5co-social li a:active, .fh5co-social li a:focus {
  outline: none;
  text-decoration: none;
}
.fh5co-social li a i {
  font-size: 15px;
}

#fh5co-footer {
  padding: 1em  0;
  background: #323a45;
  color: #878c93;
  position: fixed;
  bottom: 0;
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 300;
  font-size: 12px;
}
#fh5co-footer .fh5co-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  background: #3b3d40;
  opacity: .5;
}
#fh5co-footer .fh5co-footer-content {
  position: relative;
  z-index: 9;
}
#fh5co-footer .container .row > div {
  margin-bottom: 30px;
}
@media screen and (max-width: 992px) {
  #fh5co-footer {
    position: relative;
    padding: 5em 0;
  }
}
@media screen and (max-width: 768px) {
  #fh5co-footer {
    padding: 5em 0;
  }
}
#fh5co-footer p {
  display: block;
}
#fh5co-footer .fh5co-copyright small {
  font-size: 12px;
}
#fh5co-footer .fh5co-lead {
  font-size: 13px;
  font-family: "Montserrat", arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #fff;
  margin-bottom: 20px;
}
#fh5co-footer a {
  color: #9a9fa5;
}
#fh5co-footer a:hover, #fh5co-footer a:active, #fh5co-footer a:focus {
  color: #4fd2c2;
}
#fh5co-footer .fh5co-footer-logo {
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
#fh5co-footer .fh5co-footer-logo a {
  font-family: "Montserrat", arial, sans-serif;
  font-size: 20px;
  letter-spacing: 2px;
  color: #ccc;
  background: rgba(255, 255, 255, .05);
  float: left;
  padding: 7px 20px 5px 20px;
  text-transform: uppercase;
}
#fh5co-footer .fh5co-footer-logo a:hover {
  background: rgba(0, 0, 0, .15);
}
#fh5co-footer .fh5co-footer-logo a:hover, #fh5co-footer .fh5co-footer-logo a:active, #fh5co-footer .fh5co-footer-logo a:focus {
  color: #fff;
  border-color: #fff;
  text-decoration: none;
}
#fh5co-footer ul {
  padding: 0;
  margin: 0;
}
#fh5co-footer ul li {
  list-style: none;
  padding: 0;
  margin: 0 0 14px 0;
  line-height: 16px;
}

.fh5co-list-check {
  position: relative;
  padding: 0;
  margin: 0;
  clear: both;
}
.fh5co-list-check li {
  padding-left: 25px !important;
  position: relative;
  display: block;
  margin-bottom: 10px !important;
  line-height: 20px;
}
.fh5co-list-check li:before {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e116";
  content: "\e042";
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  color: #ccc;
}

/* Helper Classes */
/* Spacer */
.fh5co-spacer {
  clear: both;
  position: relative;
  border: none;
  padding: 0;
  margin: 0;
  display: block;
}

.fh5co-spacer-xlg {
  height: 150px;
}
@media screen and (max-width: 768px) {
  .fh5co-spacer-xlg {
    height: 70px;
  }
}

.fh5co-spacer-lg {
  height: 100px;
}
@media screen and (max-width: 768px) {
  .fh5co-spacer-lg {
    height: 50px;
  }
}

.fh5co-spacer-md {
  height: 80px;
}
@media screen and (max-width: 768px) {
  .fh5co-spacer-md {
    height: 30px;
  }
}

.fh5co-spacer-sm {
  height: 50px;
}
@media screen and (max-width: 768px) {
  .fh5co-spacer-sm {
    height: 20px;
  }
}

.fh5co-spacer-xs {
  height: 30px;
}
@media screen and (max-width: 768px) {
  .fh5co-spacer-xs {
    height: 20px;
  }
}

.fh5co-spacer-xxs {
  height: 20px;
}

@media screen and (max-width: 480px) {
  .col-xxs-12 {
    float: none;
    width: 100%;
  }
}

.fh5co-mb30 {
  margin-bottom: 30px;
}

.fh5co-row-padded {
  padding: 50px 0;
}

.fh5co-colored {
  color: #4fd2c2;
}

.image-popup {
  opacity: 1;
}
.image-popup:hover {
  opacity: .7;
}

.fh5co-social-icons > a {
  margin-right: 10px;
  color: #4fd2c2;
}
.fh5co-social-icons > a:hover, .fh5co-social-icons > a:focus, .fh5co-social-icons > a:active {
  text-decoration: none;
  color: #E4365B;
}

.fh5co-nav-toggle {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-decoration: none;
}
.fh5co-nav-toggle.active i::before, .fh5co-nav-toggle.active i::after {
  background: #fff;
}
.fh5co-nav-toggle:hover, .fh5co-nav-toggle:focus, .fh5co-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}
.fh5co-nav-toggle i {
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  width: 25px;
  height: 3px;
  color: #fff;
  font: bold 14px/.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #fff;
  transition: all .2s ease-out;
}
.fh5co-nav-toggle i::before, .fh5co-nav-toggle i::after {
  content: '';
  width: 25px;
  height: 3px;
  background: #fff;
  position: absolute;
  left: 0;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.scrolled .fh5co-nav-toggle.active i::before, .scrolled .fh5co-nav-toggle.active i::after {
  background: #000;
}
.scrolled .fh5co-nav-toggle i {
  background: #000;
}
.scrolled .fh5co-nav-toggle i::before, .scrolled .fh5co-nav-toggle i::after {
  background: #000;
}

.fh5co-nav-toggle i::before {
  top: -7px;
}

.fh5co-nav-toggle i::after {
  bottom: -7px;
}

.fh5co-nav-toggle:hover i::before {
  top: -10px;
}

.fh5co-nav-toggle:hover i::after {
  bottom: -10px;
}

.fh5co-nav-toggle.active i {
  background: transparent;
}

.fh5co-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.fh5co-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.fh5co-nav-toggle {
  float: right;
  position: relative;
  top: 12px;
  right: 0px;
  margin-right: -20px;
  z-index: 21;
  padding: 6px 0 0 0;
  display: block;
  height: 44px;
  width: 44px;
  border-bottom: none !important;
}
.fh5co-nav-toggle > span {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100px;
  margin-top: 12px;
  color: #fff;
  margin-left: -70px;
  font-family: "Montserrat", arial, sans-serif;
  letter-spacing: 2px;
  font-size: 15px;
  text-transform: uppercase;
}
.scrolled .fh5co-nav-toggle > span {
  color: #000;
}

.owl-carousel .owl-controls,
.owl-carousel-posts .owl-controls {
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .owl-carousel .owl-controls .owl-nav {
    display: none;
  }
}

.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-nav .owl-prev {
  margin-top: -19px;
  top: 50%;
  z-index: 9999;
  position: absolute;
  opacity: .5;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.owl-carousel .owl-controls .owl-nav .owl-next:hover,
.owl-carousel .owl-controls .owl-nav .owl-prev:hover {
  opacity: 1;
}

.owl-carousel .owl-controls .owl-nav .owl-next {
  right: 0;
  margin-right: -40px;
}
.owl-carousel .owl-controls .owl-nav .owl-next:hover {
  margin-right: -50px;
}
@media screen and (max-width: 992px) {
  .owl-carousel .owl-controls .owl-nav .owl-next {
    margin-right: 0px;
  }
}

.owl-carousel .owl-controls .owl-nav .owl-prev {
  left: 0;
  margin-left: -40px;
}
.owl-carousel .owl-controls .owl-nav .owl-prev:hover {
  margin-left: -50px;
}
@media screen and (max-width: 992px) {
  .owl-carousel .owl-controls .owl-nav .owl-prev {
    margin-left: 0px;
  }
}

@media screen and (max-width: 768px) {
  .owl-theme .owl-controls .owl-nav {
    display: none;
  }
}

.owl-theme .owl-controls .owl-nav [class*="owl-"] {
  background: none !important;
}
.owl-theme .owl-controls .owl-nav [class*="owl-"] i {
  font-size: 30px;
}
.owl-theme .owl-controls .owl-nav [class*="owl-"] i:hover, .owl-theme .owl-controls .owl-nav [class*="owl-"] i:focus {
  background: none !important;
}
.owl-theme .owl-controls .owl-nav [class*="owl-"]:hover, .owl-theme .owl-controls .owl-nav [class*="owl-"]:focus {
  background: none !important;
}

.owl-theme .owl-dots {
  position: absolute;
  bottom: -5em;
  width: 100%;
  text-align: center;
}

.owl-carousel-fullwidth.owl-theme .owl-dots {
  bottom: 0;
  margin-bottom: 2.5em;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  background: rgba(255, 255, 255, 0.4);
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  border: 2px solid transparent;
}
.owl-theme .owl-dots .owl-dot span:hover {
  background: none;
  background: white;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: white;
  border: 2px solid transparent;
}

.js .to-animate,
.js .animate-object,
.js .animate-box,
.js .animate-single {
  opacity: 0;
}

.fh5co-counter {
  font-size: 50px;
  display: block;
  color: #fff;
  width: 100%;
  font-family: "Montserrat", arial, sans-serif;
  margin-bottom: .5em;
}

.fh5co-counter-label {
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  font-family: "Montserrat", arial, sans-serif;
  letter-spacing: 5px;
  margin-bottom: 2em;
  display: block;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

/* Image Alignment */
img.fh5co-align-right {
  float: right;
  margin: 0 0 .5em 1em;
}
@media screen and (max-width: 480px) {
  img.fh5co-align-right {
    width: 100%;
    margin: 0 0 .5em 0;
  }
}
img.fh5co-align-left {
  float: left;
  margin: 0 1em .5em 0;
}
@media screen and (max-width: 480px) {
  img.fh5co-align-left {
    width: 100%;
    margin: 0 0 .5em 0;
  }
}
img.fh5co-align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

a > img.fh5co-align-right {
  float: right;
  margin: 0 0 .5em 1em;
}
@media screen and (max-width: 480px) {
  a > img.fh5co-align-right {
    width: 100%;
    margin: 0 0 .5em 0;
  }
}
a > img.fh5co-align-left {
  float: left;
  margin: 0 1em .5em 0;
}
@media screen and (max-width: 480px) {
  a > img.fh5co-align-left {
    width: 100%;
    margin: 0 0 .5em 0;
  }
}
a > img.fh5co-align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.fh5co-bg-section.legal-notices {
  background-image: url(/assets/images/design/bg-legal-notices.jpg);
  background-attachment: fixed;
}

.collapse-container .collapse {
  background-color: #fff;
  transition: 0.5s;
}

@each $n in 10, 25 {

  .pa#{$n} {
    padding: #{$n}px;
  }

  .pr#{$n} {
    padding-right: #{$n}px;
  }

  .pl#{$n} {
    padding-left: #{$n}px;
  }
  .pt#{$n} {
    padding-top: #{$n}px;
  }
  .pb#{$n} {
    padding-bottom: #{$n}px;
  }

}

@for $i from 1 through 8 {
  .dot-#{ $i } {
    animation-delay: #{ $i * 0.2 + 0.2 }s;
  }
}
